import { AxiosResponse } from 'axios';
import couponApi from '../api/couponApi';
import paymentApi from '../api/paymentApi';
import { TwoYearDiscount } from '../model/apiResponses/TwoYearDiscount';
import { CreateSubscription } from '../model/createSubscription';
import HttpStatus from '../model/enums/httpStatus';

export const SubscriptionService = () => {
    const getCouponDiscount = async (couponCode: string): Promise<number> => {
        const result: AxiosResponse<number> = await couponApi.getCouponDiscount(couponCode);
        console.log('result1', result);
        if (result.status === HttpStatus.OK) {
            return Promise.resolve(result.data);
        } else if (result.status === HttpStatus.UNAUTHORIZED) {
            return Promise.reject({ status: 401 } as AxiosResponse);
        } else {
            console.log('result2', true);
            return Promise.resolve(0);
        }
    };
    const getTwoYearDiscount = async (couponCode: string): Promise<TwoYearDiscount> => {
        try {
            const result: AxiosResponse<TwoYearDiscount> = await paymentApi.getTwoYearDiscount(couponCode);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    const createSubscription = async (params: CreateSubscription): Promise<number> => {
        try {
            const result: AxiosResponse<number> = await paymentApi.createSubscription(params);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error({ erro: error });
            return Promise.reject(error);
        }
    };

    const sendSubscriptionLog = async (params: CreateSubscription): Promise<void> => {
        try {
            const newParams = {log: JSON.stringify(params)};
            const result: AxiosResponse<void> = await paymentApi.sendSubscriptionLog(newParams);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error({ erro: error });
            return Promise.reject(error);
        }
    };

    return {
        sendSubscriptionLog,
        getCouponDiscount,
        getTwoYearDiscount,
        createSubscription
    };
};

export default SubscriptionService();

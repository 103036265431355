import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Row } from 'reactstrap';
import { compose } from 'redux';
import '../../App.scss';
import { IRootState } from '../../reducer';
import { logoutRequest } from '../../reducer/authentication/actions';

const RedirectAppMobile = () => {
    useEffect(() => {
        window.location.replace('https://www.wemeds.com.br/baixar');
    }, []);

    return (
        <div />
    );
};


export default withTranslation()(RedirectAppMobile);

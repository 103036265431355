import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import CustomToast from '../../components/custom-toast/custom-toast';
import ErrorPaymentModal from '../../components/error-payment-modal/error-payment-modal';
import { CreateSubscriptionResponse } from '../../model/apiResponses/CreateSubscriptionResponse';
import { CreateSubscription } from '../../model/createSubscription';
import { CustomerRegister } from '../../model/customer-register';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { IRootState } from '../../reducer';
import { registerRequest } from '../../reducer/register/actions';
import RegisterService from '../../services/registerService';
import SubscriptionService from '../../services/subscriptionService';
import AddressStep from './components/address-step';
import FinishStep from './components/finish-step';
import LoginStep from './components/login-step';
import PaymentStep from './components/payment-step';
import './register-subscription.scss';
import { StepsHeader } from './steps-header';
import { useSubscriptionContext } from './subscription.provider';

enum StepType {
    LOGIN,
    ADDRESS,
    PAYMENT,
    FINISH
}

export interface RegisterSubscriptionProps extends RouteComponentProps<{}> {
    register: (customer: CustomerRegister) => void;
    registerStatus: HttpRequestStatus;
    isAuthenticated: boolean;
    t: any;
}

const RegisterSubscription = (props: RegisterSubscriptionProps) => {
    const [createSubscriptionResponse, setCreateSubscriptionResponse] = useState<CreateSubscriptionResponse>();
    const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false);
    const { currentIndex, setCurrentIndex, setShowFinishPayment, createSubscription, setCreateSubscription } = useSubscriptionContext();
    const [showErrors, setShowErrors] = useState<boolean>();
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [paymentValue, setPaymentValue] = useState<number>(293.88);
    const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (props.isAuthenticated) {
            props.history.replace('/subscription');
        }

    }, []);

    const renderStep = () => {
        switch (currentIndex) {
            case StepType.LOGIN:
                return <LoginStep
                    createSubscription={createSubscription}
                    onNextStep={onNextStep}
                    checkIfEmailAlreadyRegistered={RegisterService.checkIfEmailAlreadyRegistered}
                    showErrors={showErrors}
                />;
            case StepType.ADDRESS:
                return <AddressStep onBack={onBack} />;
            case StepType.PAYMENT:
                return <PaymentStep
                    onNextStep={onNextStep}
                    isLoadingPayment={isLoadingPayment}
                    onBack={onBack}
                    showErrors={showErrors}
                    paymentValue={paymentValue}
                    onChangePaymentValue={onChangePaymentValue}
                />;
            case StepType.FINISH:
                return <FinishStep
                    createSubscription={createSubscription}
                    onNextStep={onNextStep}
                    isRegisterFlux
                    onBack={onBack}
                    showErrors={showErrors}
                    createSubscriptionResponse={createSubscriptionResponse!}
                />;
            default:
                return <div />;
        }
    };

    const onChangePaymentValue = (newPaymentValue: number): void => {
        setPaymentValue(newPaymentValue);
    };

    const onNextStep = (newCreateSubscription: CreateSubscription, newHasErrors: boolean): void => {
        setCreateSubscription(newCreateSubscription);
        setHasErrors(newHasErrors);
        if (hasErrors) {
            setShowErrors(true);
            toaster.notify(callback => <CustomToast title={props.t('register.error.step-1')}
                                                    onClose={callback.onClose} t={props.t} />, {
                duration: 3000,
                position: 'bottom'
            });
            return;
        }

        switch (currentIndex) {
            case StepType.LOGIN:
                setCurrentIndex(currentIndex + 1);
                setShowErrors(false);
                break;
            case StepType.PAYMENT:
                register(newCreateSubscription);
                break;
            case StepType.FINISH:
                window.fbq('track', 'Purchase');
                props.history.push('/');
                break;
            default:
                break;
        }
    };

    const register = async (newCreateSubscription: CreateSubscription): Promise<void> => {
        setIsLoadingPayment(true);
        SubscriptionService.createSubscription({ ...newCreateSubscription, newUser: true })
            .then(save => {
                const response: CreateSubscriptionResponse = { totalPaid: save };
                setCreateSubscriptionResponse(response);
                setShowErrors(false);
                setCurrentIndex(StepType.FINISH);
                setIsLoadingPayment(false);
            })
            .catch(() => {
                SubscriptionService.sendSubscriptionLog(newCreateSubscription);
                setIsLoadingPayment(false);
                setErrorModalIsOpen(true);
            });
    };

    const onBack = (): void => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        } else if (currentIndex === 0) {
            props.history.goBack();
        }
    };

    const titles: string[] = [props.t('registerSubscription.loginStep.title'), props.t('registerSubscription.addressStep.title'), props.t('registerSubscription.paymentStep.title'), props.t('registerSubscription.finishStep.title')];

    return (
        <div className="register-subscription">
            <ErrorPaymentModal open={errorModalIsOpen} close={() => setErrorModalIsOpen(false)}
                               onPressFirstButton={() => register(createSubscription)} onPressSecondButton={() => {
                setCurrentIndex(1);
                setShowFinishPayment(false);
            }} history={props.history} />
            <div className="register-subscription__header">
                <div onClick={() => props.history.push('/')} className="register-subscription__header__logo" />
            </div>
            <div className="steps">
                <StepsHeader titles={titles} />
                <div className="steps__content container">{renderStep()}</div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ register, authentication }: IRootState) => ({
    registerStatus: register.registerStatus,
    isAuthenticated: authentication.isAuthenticated
});

const mapDispatchToProps = {
    register: registerRequest
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(RegisterSubscription) as React.ComponentType<any>;

import { validate as CPFValidate } from 'gerador-validador-cpf';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import toaster from 'toasted-notes';
import Button from '../../../components/button/button';
import CustomToast from '../../../components/custom-toast/custom-toast';
import Dropdown from '../../../components/dropdown/dropdown';
import Loading from '../../../components/loading/loading';
import WemedsPhoneField from '../../../components/phone-field-meds/phone-field-meds';
import TextFieldMeds from '../../../components/text-field-meds/text-field-meds';
import { TwoYearDiscount } from '../../../model/apiResponses/TwoYearDiscount';
import { CreateSubscription } from '../../../model/createSubscription';
import SubscriptionService from '../../../services/subscriptionService';
import { INSTALLMENTS } from '../../../shared/util/installments';
import { MaskCardValidity, MaskCpf, MaskCreditNumber } from '../../../shared/util/mask-utils';
import { validateCreditCard, validatePhone } from '../../../shared/util/utils';
import { useSubscriptionContext } from '../subscription.provider';
import { Checkbox } from './checkbox';


export interface Props {
    t: (path: string, params?: any) => string;
    onNextStep: (createSubscription: CreateSubscription, hasErrors: boolean) => void;
    onBack: () => void;
    showErrors?: boolean;
    paymentValue: number;
    isLoadingPayment: boolean;
    onChangePaymentValue: (paymentValue: number) => void;
}

interface AddressErrors {
    socialNumberError?: boolean;
    cardHolderError?: boolean;
    cardNumberError?: boolean;
    phoneNumberError?: boolean;
    cardValidityError?: boolean;
    cardCodeError?: boolean;
    installmentNumberError?: boolean;
}

export const PaymentStep = (props: Props): JSX.Element => {
    const [errors, setErrors] = useState<AddressErrors>({
        socialNumberError: undefined,
        cardHolderError: undefined,
        cardNumberError: undefined,
        phoneNumberError: undefined,
        cardValidityError: undefined,
        cardCodeError: undefined,
        installmentNumberError: undefined
    });

    let typingWaiting;
    const DEFAULT_PRICE = '419,80';
    const [socialNumber, setSocialNumber] = useState<string | undefined>(undefined);
    const [cardHolder, setCardHolder] = useState<string | undefined>(undefined);
    const [cardNumber, setCardNumber] = useState<string | undefined>(undefined);
    const [cardValidity, setCardValidity] = useState<string | undefined>(undefined);
    const [cardCode, setCardCode] = useState<string | undefined>(undefined);
    // tslint:disable-next-line: no-unused-declaration
    const [installmentNumber, setInstallmentNumber] = useState<number | undefined>(undefined);
    const [coupon, setCoupon] = useState<string | undefined>(undefined);
    const [phone, setPhone] = useState<string | undefined>("55 ");
    const [couponDiscount, setCouponDiscount] = useState<number | undefined>(undefined);
    const [lastCouponAttempt, setLastCouponAttempt] = useState<string | undefined>(undefined);
    const [showCouponDiscount, setShowCouponDiscount] = useState<boolean>(false);
    const [isLoadingCoupon, setIsLoadingCoupon] = useState<boolean>(false);
    const [accept2Years, setAccept2Years] = useState<boolean | undefined>(undefined);
    const [twoYearDiscount, setTwoYearDiscount] = useState<TwoYearDiscount | undefined>(undefined);
    const [showNote, setShowNote] = useState<boolean>(false);
    const {
        createSubscription,
        showFinishPayment,
        setShowFinishPayment,
        setCreateSubscription
    } = useSubscriptionContext();

    const mapInstallments = (value: number) => {
        return INSTALLMENTS.map((it, index) => ({
            label: `${it} - Sem Juros de ${(value / (index + 1)).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
            })}`,
            value: index + 1
        }));
    };

    useEffect(() => {
        window.fbq('track', 'AddPaymentInfo');
    }, []);

    const removeFormatString = (value: string): string => {
        return value.replace(/\D/g, '');
    };

    const onNextStep = (): void => {
        const isValid: boolean = validateInputs();
        if (isValid) {
            if (showFinishPayment) {
                const newCreateSubscription = {
                    ...createSubscription,
                    twoYearsPromotion: accept2Years || false,
                    parcelNumber: accept2Years ? Number(installmentNumber ?? 1) : createSubscription.paymentMethod.parcelNumber
                };
                if (accept2Years) {
                    props.onChangePaymentValue(twoYearDiscount ? twoYearDiscount.total : props.paymentValue);
                } else {
                    props.onChangePaymentValue(props.paymentValue - (couponDiscount || 0));
                }
                props.onNextStep(newCreateSubscription, !isValid);
            } else {
                const formatedPhone = phone != null ? phone.replace(/[\(\)+]/g, '').trim() : '';
                const newCreateSubscription: CreateSubscription = {
                    ...createSubscription,
                    twoYearsPromotion: accept2Years || false,
                    couponCode: coupon,
                    paymentMethod: {
                        ...createSubscription.paymentMethod,
                        identificationNumber: removeFormatString(socialNumber || ''),
                        holderName: cardHolder,
                        creditCardNumber: removeFormatString(cardNumber || ''),
                        mobilePhone: {
                            country_code: formatedPhone!.substring(0, 2),
                            area_code: formatedPhone!.substring(3, 5),
                            number: formatedPhone!.split(" ")[2]
                        },
                        validity: cardValidity,
                        code: cardCode != null ? _.replace(cardCode, '_', '') : '',
                        parcelNumber: Number(installmentNumber ?? 1)
                    }
                };
                setCreateSubscription(newCreateSubscription);
                setShowFinishPayment(true);
                setShowNote(true);
                window.fbq('trackCustom', 'Upsell');
            }
        } else {
            toaster.notify(callback => <CustomToast title={props.t('register.error.step-1')}
                                                    onClose={callback.onClose} t={props.t} />, {
                duration: 3000,
                position: 'bottom'
            });
        }
    };
    const onBack = (): void => {
        if (showFinishPayment) {
            setShowFinishPayment(false);
        } else {
            props.onBack();
        }
    };
    const validateInputs = (): boolean => {
        return (
            (accept2Years != null || !showFinishPayment) &&
            !validateSocialNumber(socialNumber) &&
            !validateCardHolder(cardHolder) &&
            !validateCardNumber(cardNumber) &&
            !validatePhoneNumber(phone) &&
            !validateCardValidity(cardValidity) &&
            !validateCardCode(cardCode) &&
            !validateInstallmentNumber(installmentNumber)
        );
    };
    const onChangeSocialNumber = (value: string): void => {
        setSocialNumber(value);
    };
    const validateSocialNumber = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length < 11 || !CPFValidate(value || '');
        setErrors({ ...errors, socialNumberError: hasError });
        return hasError;
    };
    const onChangeCardHolder = (value: string): void => {
        setCardHolder(value);
    };
    const validateCardHolder = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length === 0;
        setErrors({ ...errors, cardHolderError: hasError });
        return hasError;
    };
    const onChangeCardNumber = (value: string): void => {
        setCardNumber(value);
    };
    const validateCardNumber = (value?: string): boolean => {
        // const hasError: boolean = value == null || value.trim().length === 0 || !validateCreditCard(value.replace(/ /g, ''));
        const hasError: boolean = false;
        setErrors({ ...errors, cardNumberError: hasError });
        return hasError;
    };
    const validatePhoneNumber = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length === 0 || !validatePhone(value.replace(/[\s\(\)+]/g, ''));
        setErrors({ ...errors, phoneNumberError: hasError });
        return hasError;
    };
    const onChangeCardValidity = (value: string): void => {
        setCardValidity(value);
    };
    const validateCardValidity = (value?: string): boolean => {
        const month: number = value ? Number(value.split('/')[0]) : 0;
        const year: number = value ? 2000 + Number(value.split('/')[1]) : 0;
        const currentYear: number = new Date().getFullYear();
        const hasError: boolean =
            value == null || value.trim().length !== 5 || month === 0 || month > 12 || year === 0 || year < currentYear;
        setErrors({ ...errors, cardValidityError: hasError });
        return hasError;
    };
    const onChangeCardCode = (value: string): void => {
        setCardCode(value);
    };
    const validateCardCode = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length < 3 || value.trim().length > 4;
        setErrors({ ...errors, cardCodeError: hasError });
        return hasError;
    };
    const onChangeInstallmentNumber = (value: number): void => {
        setInstallmentNumber(value);
    };
    const validateInstallmentNumber = (value?: number): boolean => {
        const hasError: boolean = value == null;
        setErrors({ ...errors, installmentNumberError: hasError });
        return hasError;
    };
    const onChange2YearInstallmentNumber = (value: number): void => {
        setInstallmentNumber(value);
    };
    const getCouponDiscount = async (couponCode: string): Promise<void> => {
        if (couponCode.trim().length === 0 || couponCode === lastCouponAttempt) {
            setCouponDiscount(undefined);
            setShowCouponDiscount(false);
            setLastCouponAttempt(undefined);
            return;
        }
        setIsLoadingCoupon(true);
        setShowCouponDiscount(false);
        setLastCouponAttempt(couponCode);
        setTimeout(() => {
            setIsLoadingCoupon(false);
            setShowCouponDiscount(true);
        }, 5000);
        SubscriptionService.getCouponDiscount(couponCode).then(it => {
            if (it > 0) {
                setCouponDiscount(it);
            } else {
                setCouponDiscount(undefined);
            }
            setIsLoadingCoupon(false);
            setShowCouponDiscount(true);
        });
        clearTimeout(0);
    };
    const onChangeCoupon = (value: string): void => {
        setCoupon(value);
        setShowCouponDiscount(false);
        setIsLoadingCoupon(true);
        setCouponDiscount(undefined);
        if (typingWaiting) {
            clearTimeout(typingWaiting);
        }
        typingWaiting = setTimeout(() => {
            typingWaiting = null;
            getCouponDiscount(value);
        }, 2000);
    };
    const getTwoYearDiscount = async (): Promise<void> => {
        const response: TwoYearDiscount = await SubscriptionService.getTwoYearDiscount(coupon || '');
        setTwoYearDiscount(response);
    };
    const onChangeAccept2Years = (accept: boolean): void => {
        if (accept) {
            getTwoYearDiscount();
        }
        setAccept2Years(accept);
    };

    const onChangePhoneNumber = (newPhone: string) => {
        setPhone(newPhone);
    }

    const onCloseNote = (): void => {
        setShowNote(false);
    };

    const renderMobileHeader = (marginLeft?: string) => {
        return (
            <>
                <div className="login-step__inputs-mobile-done" style={{ marginLeft }}>
                    <div style={{ display: 'flex', marginLeft: '23px' }}>
                        <div className="login-step__inputs-mobile-done--icon-done" />
                        <div
                            className="login-step__inputs-mobile-done--text">{props.t('register.loginStep.title')}</div>
                    </div>
                    <div style={{ display: 'flex', marginRight: '23px' }}>
                        <div className="login-step__inputs-mobile-done--edit">{props.t('register.step.edit')}</div>
                        <div className="login-step__inputs-mobile-done--icon-arrow" />
                    </div>
                </div>
                <div className="login-step__inputs-mobile-done" style={{ marginLeft }}>
                    <div style={{ display: 'flex', marginLeft: '23px' }}>
                        <div className="login-step__inputs-mobile-done--icon-done" />
                        <div
                            className="login-step__inputs-mobile-done--text">{props.t('registerSubscription.addressStep.title')}</div>
                    </div>
                    <div style={{ display: 'flex', marginRight: '23px' }}>
                        <div className="login-step__inputs-mobile-done--edit">{props.t('register.step.edit')}</div>
                        <div className="login-step__inputs-mobile-done--icon-arrow" />
                    </div>
                </div>
            </>
        );
    };

    if (showFinishPayment) {
        return (
            <div className="payment-step">
                <div className="payment-finish">
                    <div className="premium-logo premium-logo-payment" />
                    {renderMobileHeader('-8px')}
                    <Row style={{ width: '100%' }}>
                        <Col>
                            <div className="login-step__inputs-mobile">
                                <div style={{ display: 'flex' }}>
                                    <div className="login-step__inputs-mobile--icon-active" />
                                    <div className="login-step__inputs-mobile--text">
                                        {props.t('registerSubscription.paymentStep.title')}
                                    </div>
                                </div>
                                <div className="login-step__inputs-mobile--icon-arrow" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: '6', offset: '3' }} xs="12">
                            <p className="payment-finish__title">{props.t('registerSubscription.paymentStep.finish.title')}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: '6', offset: '3' }} xs="12">
                            <div className="payment-finish__card">
                                <div className="payment-finish__card__header">
                                    <div className="payment-finish__card__header__coupon-icon" />
                                    <span>
                                        <span className="payment-finish__card__header__title-1">
                                            {props.t('registerSubscription.paymentStep.finish.2YearDiscount.title1').toUpperCase()}
                                        </span>
                                        <span className="payment-finish__card__header__title-2">
                                            {props.t('registerSubscription.paymentStep.finish.2YearDiscount.title2').toUpperCase()}
                                        </span>
                                    </span>
                                    <div className="payment-finish__card__header__logo" />
                                    <div className="payment-finish__card__header__line" />
                                    <span style={{ width: 198, textAlign: 'center' }}>
                                        <span className="payment-finish__card__header__title-3">
                                            {props.t('registerSubscription.paymentStep.finish.2YearDiscount.title3').toUpperCase()}
                                        </span>
                                        <span
                                            className="payment-finish__card__header__title-3 payment-finish__card__header__title-3--white">
                                            {props.t('registerSubscription.paymentStep.finish.2YearDiscount.title4').toUpperCase()}
                                        </span>
                                        <span className="payment-finish__card__header__title-3">
                                            {props.t('registerSubscription.paymentStep.finish.2YearDiscount.title5').toUpperCase()}
                                        </span>
                                    </span>
                                </div>
                                <div className="payment-finish__card__content">
                                    <div className="payment-finish__card__content__checkboxes">
                                        <Checkbox
                                            onChange={() => {
                                                onChangeAccept2Years(true);
                                                setErrors({ ...errors, installmentNumberError: undefined });
                                            }}
                                            checked={accept2Years || false}
                                            label={props.t('registerSubscription.paymentStep.finish.2YearDiscount.checkboxLabel1')}
                                        />
                                        <Checkbox
                                            onChange={() => onChangeAccept2Years(false)}
                                            checked={accept2Years != null ? !accept2Years : false}
                                            label={props.t('registerSubscription.paymentStep.finish.2YearDiscount.checkboxLabel2')}
                                        />
                                    </div>
                                    {accept2Years && (
                                        <div>
                                            <div className="payment-finish__card__content__line" />
                                            {twoYearDiscount ? (
                                                <div>
                                                    <div className="payment-finish__card__content__details">
                                                        <p className="payment-finish__card__content__details__title">
                                                            {props
                                                                .t(
                                                                    'registerSubscription.paymentStep.finish.2YearDiscount.shoppingDetails.title'
                                                                )
                                                                .toUpperCase()}
                                                        </p>
                                                        <div className="payment-finish__card__content__details__row">
                                                            <span
                                                                className="payment-finish__card__content__details__row__label">
                                                                {`${props.t(
                                                                    'registerSubscription.paymentStep.finish.2YearDiscount.shoppingDetails.firstYear1'
                                                                )} ${
                                                                    couponDiscount
                                                                        ? props.t(
                                                                            'registerSubscription.paymentStep.finish.2YearDiscount.shoppingDetails.firstYear2',
                                                                            {
                                                                                value: (Number(props.paymentValue) - couponDiscount).toLocaleString('pt-br', {
                                                                                    style: 'currency',
                                                                                    currency: 'BRL'
                                                                                })
                                                                            }
                                                                        )
                                                                        : ''
                                                                }`}
                                                            </span>
                                                            <div
                                                                className="payment-finish__card__content__details__row__line" />
                                                            <span
                                                                className="payment-finish__card__content__details__row__label">
                                                                {twoYearDiscount.discountWithCoupon.toLocaleString('pt-br', {
                                                                    style: 'currency',
                                                                    currency: 'BRL'
                                                                })}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className="payment-finish__card__content__details__row"
                                                            style={{ marginBottom: 12 }}
                                                        >
                                                            <span
                                                                className="payment-finish__card__content__details__row__label">
                                                                {props.t(
                                                                    'registerSubscription.paymentStep.finish.2YearDiscount.shoppingDetails.secondYear'
                                                                )}
                                                            </span>
                                                            <div
                                                                className="payment-finish__card__content__details__row__line" />
                                                            <span
                                                                className="payment-finish__card__content__details__row__label">
                                                                {(147.45).toLocaleString('pt-br', {
                                                                    style: 'currency',
                                                                    currency: 'BRL'
                                                                })}
                                                            </span>
                                                        </div>
                                                        <div className="payment-finish__card__content__line" />
                                                        <div className="payment-finish__card__content__details__total">
                                                            <p className="payment-finish__card__content__details__total__title">
                                                                {props.t(
                                                                    'registerSubscription.paymentStep.finish.2YearDiscount.shoppingDetails.total'
                                                                )}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'flex-end'
                                                                }}
                                                            >
                                                                <div className="simple-row">
                                                                    <span>
                                                                        <span
                                                                            className="payment-finish__card__content__details__total__from-text">
                                                                            {props.t(
                                                                                'registerSubscription.paymentStep.finish.2YearDiscount.shoppingDetails.from'
                                                                            )}
                                                                        </span>
                                                                        <span
                                                                            className="payment-finish__card__content__details__total__from-text line-through">{`R$${(839.6).toLocaleString(
                                                                            'pt-br',
                                                                            { minimumFractionDigits: 2 }
                                                                        )}`}</span>
                                                                    </span>
                                                                    <div
                                                                        className="payment-finish__card__content__details__total__separator" />
                                                                    <div className="simple-row">
                                                                        <span
                                                                            className="payment-finish__card__content__details__total__from-text">
                                                                            {props.t(
                                                                                'registerSubscription.paymentStep.finish.2YearDiscount.shoppingDetails.for'
                                                                            )}
                                                                        </span>
                                                                        <span
                                                                            className="payment-finish__card__content__details__total__value">
                                                                            {`R$${twoYearDiscount.total.toLocaleString('pt-br', {
                                                                                minimumFractionDigits: 2
                                                                            })}`}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <span
                                                                    className="payment-finish__card__content__details__total__subtitle">
                                                                    {props.t(
                                                                        'registerSubscription.paymentStep.finish.2YearDiscount.shoppingDetails.priceSubtitle',
                                                                        {
                                                                            value: `R$${twoYearDiscount.saved.toLocaleString('pt-br', {
                                                                                minimumFractionDigits: 2
                                                                            })}`
                                                                        }
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="payment-finish__card__content__line" />
                                                    </div>
                                                    <div className="payment-finish__card__content__installments">
                                                        <p className="payment-finish__card__content__installments__title">
                                                            {props
                                                                .t(
                                                                    'registerSubscription.paymentStep.finish.2YearDiscount.installmentsTitle'
                                                                )
                                                                .toUpperCase()}
                                                        </p>
                                                        <Dropdown
                                                            data={mapInstallments(twoYearDiscount.total ?? 0)}
                                                            title={props.t(
                                                                'registerSubscription.paymentStep.finish.2YearDiscount.installmentsPlaceholder'
                                                            )}
                                                            onClickItem={onChange2YearInstallmentNumber}
                                                            style={{ width: '100%' }}
                                                            error={errors.installmentNumberError}
                                                            errorText={props.t('registerSubscription.paymentStep.errors.installmentNumber')}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <Loading />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col>
                            {showNote && (
                                <div className="register-subscription__note" style={{ paddingBottom: 8 }}>
                                    <span style={{ lineHeight: '17px', marginTop: 8 }}>
                                        <span className="register-subscription__note__text">
                                            {props.t('registerSubscription.paymentStep.sideNoteText1')}
                                        </span>
                                        <span
                                            className="register-subscription__note__text register-subscription__note__text--bold">
                                            {props.t('registerSubscription.paymentStep.sideNoteText2')}
                                        </span>
                                        <span className="register-subscription__note__text">
                                            {props.t('registerSubscription.paymentStep.sideNoteText3')}
                                        </span>
                                    </span>
                                    <div className="register-subscription__note__close-icon" onClick={onCloseNote} />
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
                <Row style={{ width: '100%' }}>
                    <Col md={{ size: '6', offset: '3' }} xs="12">
                        <div className="steps__footer">
                            <Button
                                label={props.t('diagnApp.back')}
                                inverse
                                onPress={onBack}
                                style={{ width: 151, marginRight: 13 }}
                                leftIcon={<div className="steps__footer__back-icon" />}
                            />
                            <Button
                                isLoading={props.isLoadingPayment}
                                label={'Finalizar'}
                                onPress={onNextStep}
                                style={{ width: 151, marginLeft: 13 }}
                                rightIcon={<div className="steps__footer__advance-icon" />}
                            />
                        </div>
                    </Col>
                </Row>
                <div className="login-step__inputs-mobile-disabled--all">
                    <div className="login-step__inputs-mobile-disabled">
                        <div
                            className="login-step__inputs-mobile-disabled--text">{props.t('registerSubscription.finishStep.title')}</div>
                        <div className="login-step__inputs-mobile-disabled--icon-arrow" />
                    </div>
                </div>
                {props.isLoadingPayment && (
                    <div className="loading-overlay">
                        <div className="loading-img" />
                    </div>
                )}
                {props.isLoadingPayment && (
                    <div className="loading-overlay" style={{ zIndex: 10, opacity: 1, backgroundColor: 'transparent' }}>
                        <div style={{
                            fontWeight: 'bold',
                            marginTop: '70px'
                        }}>{props.t('registerSubscription.paymentStep.waitPayment')}</div>
                    </div>
                )}
            </div>
        );
    }
    const savedPrice: string = (124 + (couponDiscount || 0)).toString();
    const flagPriceFontSize: number = savedPrice.split('.').length > 1 ? (savedPrice.split('.')[1].length === 2 ? 20 : 22) : 26;
    return (
        <div className="payment-step">
            <div className="premium-logo" />
            {renderMobileHeader()}
            <Row style={{ width: '100%' }}>
                <Col>
                    <div className="login-step__inputs-mobile">
                        <div style={{ display: 'flex' }}>
                            <div className="login-step__inputs-mobile--icon-active" />
                            <div
                                className="login-step__inputs-mobile--text">{props.t('registerSubscription.paymentStep.title')}</div>
                        </div>
                        <div className="login-step__inputs-mobile--icon-arrow" />
                    </div>
                </Col>
            </Row>
            <div className="payment-step__inputs">
                <Row>
                    <Col md={{ size: '6', offset: '3' }} xs="12">
                        <TextFieldMeds
                            onChange={onChangeSocialNumber}
                            id="socialNumber"
                            value={socialNumber}
                            errorText={props.t('registerSubscription.paymentStep.errors.socialNumber')}
                            label={props.t('registerSubscription.paymentStep.placeholders.socialNumber')}
                            placeholder={props.t('registerSubscription.paymentStep.placeholders.socialNumber')}
                            style={{ marginBottom: 25 }}
                            error={errors.socialNumberError}
                            InputProps={{
                                inputComponent: MaskCpf,
                                value: socialNumber
                            }}
                            onBlur={() => validateSocialNumber(socialNumber)}
                        />

                        <TextFieldMeds
                            onChange={onChangeCardHolder}
                            id="cardHolder"
                            value={cardHolder}
                            errorText={props.t('registerSubscription.paymentStep.errors.cardHolder')}
                            label={props.t('registerSubscription.paymentStep.placeholders.cardHolder')}
                            placeholder={props.t('registerSubscription.paymentStep.placeholders.cardHolder')}
                            style={{ marginBottom: 25 }}
                            error={errors.cardHolderError}
                        />

                        <WemedsPhoneField
                            onChange={onChangePhoneNumber}
                            value={phone}
                            errorText={props.t('registerSubscription.paymentStep.errors.phoneNumber')}
                            label={props.t('registerSubscription.paymentStep.placeholders.phoneNumber')}
                            placeholder={props.t('registerSubscription.paymentStep.placeholders.phoneNumber')}
                            style={{ marginBottom: 25 }}
                            error={errors.phoneNumberError}
                            onBlur={() => validatePhoneNumber(phone)}
                        />

                        <TextFieldMeds
                            onChange={onChangeCardNumber}
                            id="cardNumber"
                            value={cardNumber}
                            errorText={props.t('registerSubscription.paymentStep.errors.cardNumber')}
                            label={props.t('registerSubscription.paymentStep.placeholders.cardNumber')}
                            placeholder={props.t('registerSubscription.paymentStep.placeholders.cardNumber')}
                            style={{ marginBottom: 25 }}
                            error={errors.cardNumberError}
                            InputProps={{
                                inputComponent: MaskCreditNumber,
                                value: cardNumber
                            }}
                            onBlur={() => validateCardNumber(cardNumber)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: '3', offset: '3' }} style={{ paddingRight: '7.5px' }} xs="6">
                        <TextFieldMeds
                            onChange={onChangeCardValidity}
                            id="cardValidity"
                            value={cardValidity}
                            errorText={props.t('registerSubscription.paymentStep.errors.cardValidity')}
                            label={props.t('registerSubscription.paymentStep.placeholders.cardValidity')}
                            placeholder={props.t('registerSubscription.paymentStep.placeholders.cardValidity')}
                            style={{ marginRight: 24 }}
                            error={errors.cardValidityError}
                            InputProps={{
                                inputComponent: MaskCardValidity,
                                value: cardValidity
                            }}
                            onBlur={() => validateCardValidity(cardValidity)}
                        />
                    </Col>
                    <Col md={{ size: '3' }} style={{ paddingLeft: '7.5px' }} xs="6">
                        <TextFieldMeds
                            onChange={onChangeCardCode}
                            id="cardCode"
                            value={cardCode}
                            errorText={props.t('registerSubscription.paymentStep.errors.cardCode')}
                            label={props.t('registerSubscription.paymentStep.placeholders.cardCode')}
                            placeholder={props.t('registerSubscription.paymentStep.placeholders.cardCode')}
                            error={errors.cardCodeError}
                            onBlur={() => validateCardCode(cardCode)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: '6', offset: '3' }} xs="12">
                        <Dropdown
                            data={mapInstallments((Number(props.paymentValue) ?? 0) - (couponDiscount || 0))}
                            title={props.t('registerSubscription.paymentStep.placeholders.installmentNumber')}
                            onClickItem={onChangeInstallmentNumber}
                            style={{ marginBottom: 25, marginTop: 25 }}
                            error={errors.installmentNumberError}
                            errorText={props.t('registerSubscription.paymentStep.errors.installmentNumber')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: '6', offset: '3' }} xs="12">
                        <div className="payment-step__banner">
                            <div className="payment-step__banner__annual-plan">
                                <span className="payment-step__banner__annual-plan__text">
                                    {props.t('registerSubscription.paymentStep.banner.annualPlan')}
                                </span>
                            </div>
                            <div className="payment-step__banner__premium">
                                <div className="payment-step__banner__premium__img-1" />
                                <div className="payment-step__banner__premium__img-2" />
                            </div>
                            <div className="payment-step__banner__prices">
                                <span>
                                    <span className="payment-step__banner__prices__title-1">
                                        {props.t('registerSubscription.paymentStep.banner.timesOf1', {
                                            installments: installmentNumber || 12
                                        })}
                                    </span>
                                    <span className="payment-step__banner__prices__title-2">
                                        {((Number(props.paymentValue) - (couponDiscount || 0)) / (installmentNumber || 12)).toLocaleString(
                                            'pt-br',
                                            {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }
                                        )}
                                    </span>
                                    <span className="payment-step__banner__prices__title-1">
                                        {props.t('registerSubscription.paymentStep.banner.timesOf2')}
                                    </span>
                                </span>
                                <span>
                                    <span className="payment-step__banner__prices__title-3">
                                        {props.t('registerSubscription.paymentStep.banner.from')}
                                    </span>
                                    <span
                                        className="payment-step__banner__prices__title-3 line-through">{`R$${DEFAULT_PRICE}`}</span>
                                    <span className="payment-step__banner__prices__title-3"
                                          style={{ marginRight: 3, marginLeft: 3 }}>
                                        {' | '}
                                    </span>
                                    <span className="payment-step__banner__prices__title-3">
                                        {props.t('registerSubscription.paymentStep.banner.by')}
                                    </span>
                                    <span className="payment-step__banner__prices__title-3">
                                        {`R$${(Number(props.paymentValue) - (couponDiscount || 0)).toLocaleString('pt-br', {
                                            minimumFractionDigits: 2
                                        })}`}
                                    </span>
                                </span>
                            </div>
                            <div className="payment-step__banner__flag">
                                <div className="payment-step__banner__flag__background-img" />
                                <span className="payment-step__banner__flag__text-1">
                                    {props.t('registerSubscription.paymentStep.banner.flagText1')}
                                </span>
                                <span style={{ lineHeight: '24px' }}>
                                    <span className="payment-step__banner__flag__text-2">
                                        {props.t('registerSubscription.paymentStep.banner.flagText2')}
                                    </span>
                                    <span className="payment-step__banner__flag__text-3"
                                          style={{ fontSize: flagPriceFontSize }}>
                                        {couponDiscount
                                            ? (124 + couponDiscount).toLocaleString('pt-br', {
                                                maximumFractionDigits: 2
                                            })
                                            : 124}
                                    </span>
                                </span>
                                <span className="payment-step__banner__flag__text-4">
                                    {props.t('registerSubscription.paymentStep.banner.flagText4')}
                                </span>
                            </div>
                        </div>
                        <div className="payment-step__coupon">
                            <p className="payment-step__coupon__title">{props.t('registerSubscription.paymentStep.couponTitle')}</p>
                            <div className="payment-step__coupon__content">
                                <TextFieldMeds
                                    onChange={onChangeCoupon}
                                    id="coupon"
                                    value={coupon}
                                    label={props.t('registerSubscription.paymentStep.couponPlaceholder')}
                                    placeholder={props.t('registerSubscription.paymentStep.couponPlaceholder')}
                                    // onBlur={() => getCouponDiscount(coupon || '')}
                                    // onEnterPress={() => getCouponDiscount(coupon || '')}
                                    error={couponDiscount ? false : showCouponDiscount ? true : undefined}
                                />
                                {isLoadingCoupon && <Loading />}
                                {showCouponDiscount && (
                                    <div className="simple-row" style={{ width: '100%' }}>
                                        <div className="payment-step__coupon__arrow-icon" />
                                        {couponDiscount ? (
                                            <div className="payment-step__coupon__result">
                                                <p className="payment-step__coupon__result__success-title">
                                                    {props.t('registerSubscription.paymentStep.couponSuccess').toUpperCase()}
                                                </p>
                                                <span style={{ marginTop: -10 }}>
                                                    <span
                                                        className="payment-step__coupon__result__success-value-extension">{'R$ '}</span>
                                                    <span className="payment-step__coupon__result__success-value">
                                                        {couponDiscount.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                                                    </span>
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="payment-step__coupon__result"
                                                 style={{ justifyContent: 'center' }}>
                                                <p className="payment-step__coupon__result__success-title">
                                                    {props.t('registerSubscription.paymentStep.couponError').toUpperCase()}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="steps__footer">
                            <Button
                                label={props.t('diagnApp.back')}
                                inverse
                                onPress={onBack}
                                style={{ maxWidth: 151, marginRight: 13 }}
                                leftIcon={<div className="steps__footer__back-icon" />}
                            />
                            <Button
                                label={props.t('diagnApp.advance')}
                                onPress={onNextStep}
                                style={{ maxWidth: 151, marginLeft: 13 }}
                                rightIcon={<div className="steps__footer__advance-icon" />}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="login-step__inputs-mobile-disabled--all">
                <div className="login-step__inputs-mobile-disabled">
                    <div
                        className="login-step__inputs-mobile-disabled--text">{props.t('registerSubscription.finishStep.title')}</div>
                    <div className="login-step__inputs-mobile-disabled--icon-arrow" />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(PaymentStep) as React.ComponentType<
    Omit<Props, 't'>
>;

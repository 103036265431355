import { AxiosResponse } from 'axios';
import { TwoYearDiscount } from '../model/apiResponses/TwoYearDiscount';
import { CreateSubscription } from '../model/createSubscription';
import { api } from './api';

export const paymentApi = () => {
    const getTwoYearDiscount = (couponCode: string): Promise<AxiosResponse<TwoYearDiscount>> => {
        return api.get<TwoYearDiscount>(`/web-payment/two-years`, { params: { couponCode } });
    };
    const createSubscription = (params: CreateSubscription): Promise<AxiosResponse<number>> => {
        return api.post<number>(`/web-payment/assign`, params);
    };
    const sendSubscriptionLog = (params: {log: string}): Promise<AxiosResponse<void>> => {
        return api.post<void>(`/web-payment/log`, params);
    };

    return {
        sendSubscriptionLog,
        getTwoYearDiscount,
        createSubscription
    };
};

export default paymentApi();
